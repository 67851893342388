/// <reference path="../../node_modules/@types/jquery/index.d.ts" />
import { Faro } from '@grafana/faro-web-sdk';
import * as ace from 'ace-builds-1.15.2';
import { ampli } from './amplitude/ampli';
import { host as configHost, lib as libConfig } from './config.json';
import './lib';
import './faro';
import { getSanisedHtml } from './html-sanitiser';
import { setUrlData } from './location';
import { loadCkFinder, getDependencyVersions, getSdkRootUrl, loadScript, loadScripts, importLoadScripts, logEvent, getLoggerConfig, getScriptData, handleUnmonitoredInterface, getInterfaceData } from './util';
import { displayDomainValidationWarning } from './domain-validation-warning';

const { requestIdleCallback = setTimeout, EV_CORE_META } = window;
const EV_CORE = {
    getSanisedHtml,
    getDependencyVersions,
    getSdkRootUrl,
    loadScript,
    loadScripts,
    logEvent,
    getLoggerConfig,
    getScriptData,
    getInterfaceData
};
const eventName = {
    load: 'load'
};
const API = {
    invalidDomains: '/domains/invalid-domains'
} as const;

export const ErrorMessage = {
    errorGettingInvalidDomains: 'Error Getting Invalid Domains'
} as const;

window.EV_CORE = EV_CORE;

addEventListener(eventName.load, handleLoad);

function handleLoad() {
    requestIdleCallback(importLoadScripts);

    requestIdleCallback(setUrlData);

    requestIdleCallback(loadCkFinder);

    requestIdleCallback(displayDomainValidationWarning);

    if (!EV_CORE_META) {
        requestIdleCallback(handleUnmonitoredInterface);
    }
}

interface IEV_CORE_META {
    e?: string;
    c?: string;
    u?: string;
    i?: string;
    v?: string;
}

interface ICkFinderConfigModal {
    width: number;
    height: number;
    readOnly: boolean;
}

interface ICkFinder {
    popup: (config: ICkFinderConfigModal) => void
}

interface IJQueryFnTooltip {
    Constructor?: {
        VERSION?: string;
    }
}

interface IJQueryFn {
    tooltip?: IJQueryFnTooltip;
}

interface IJQuery {
    ui: {
        version: string;
    };
    fn?: IJQueryFn;
}

interface IInvalidDomain {
    domain_id: string;
    domain_domain: string;
}

interface IHtmlApi {
    URL: typeof URL
}

interface IAngularJsVersion {
    full: string;
}

interface IAngularJs {
    version: IAngularJsVersion
}

export interface IFetchDataErrorResponse { 
    error?: Error;
    text?: string;
}

export interface IGetInvalidDomainsParams {
    url: Api['invalidDomains'];
}

export interface IGetInvalidDomainsResponse {
    invalidDomains: IInvalidDomain[];
}

type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T];
type Ace = typeof ace;
type AceRequire = Ace['require'] & NodeRequire;

export type FetchDataParams = IGetInvalidDomainsParams;
export type FetchDataResponse<P extends FetchDataParams> = P extends IGetInvalidDomainsParams ? IGetInvalidDomainsResponse
    : unknown;
export type HostKey = keyof typeof configHost;
export type AceVersion = keyof typeof libConfig.ace;
export type Api = typeof API;

export function ObjectEntries<T extends object>(t: T): Entries<T>[] {
  return Object.entries(t) as any;
}

declare global {
    interface Window {
        URL?: typeof URL;
        HTML_API: IHtmlApi;
        EV_CORE_META?: IEV_CORE_META;
        EV_CORE: (typeof EV_CORE);
        EV_LOGGER?: Faro;
        CKFinder: ICkFinder;
        jQuery?: JQueryStatic & IJQuery;
        $: JQueryStatic;
        'domain-validation-warning-template'?: HTMLTemplateElement,
        ace: Ace & { define?: Function, require?: AceRequire },
        define?: Function,
        require?: AceRequire,
        angular?: IAngularJs,
        'everlytic-origin'?: HTMLIFrameElement;
        AMPLITUDE?: typeof ampli;
        'stick-nav-username'?: HTMLElement;
    }
}

export { };