import { camelCase, startCase } from 'lodash-es';

const EventName = {
    unknown: 'UNKNOWN'
} as const;
const Suffix = {
    readableLabel: 'Label'
} as const;

export function logEvent(params: ILogEvent) {
    const { EV_LOGGER: logger, AMPLITUDE } = window;
    const { eventName = EventName.unknown, attributes = {}, readableLabels = [], amplitude = false } = params;
    const updatedAttributes = getUpdatedAtributes({ attributes, readableLabels });

    logger?.api?.pushEvent?.(eventName, updatedAttributes);

    if (amplitude && AMPLITUDE) {
        AMPLITUDE.client.logEvent(eventName, updatedAttributes);
    }
}

function getUpdatedAtributes({ attributes = {}, readableLabels = [] }: Pick<ILogEvent, 'attributes' | 'readableLabels'>) {
    const updatedAttributes = {
        ...attributes
    };

    let readableLabelKey: string;

    for (const readableLabel of readableLabels) {
        if (attributes[readableLabel]) {
            readableLabelKey = camelCase(`${readableLabel}${Suffix.readableLabel}`);

            updatedAttributes[readableLabel] = camelCase(attributes[readableLabel]);
            updatedAttributes[readableLabelKey] = startCase(attributes[readableLabel]);
        }
    }

    return updatedAttributes;
}

interface ILogEvent {
    eventName: string;
    attributes?: Record<string, string>,
    readableLabels?: string[];
    amplitude?: boolean;
}